import React from 'react'
import "./Services.css"

function serviceCards(props) {
  return (
    <>
          <div className='servicePoint'>
                  <div className="backContainer">
                        <div className="serviceCard">
                              <div className="cardDetails">
                                    <h2>{props.title}</h2>
                                    <p>{props.detail}</p>
                              </div>
                        </div>
                  </div>
                  <div className='outerSectionPointImg'>
                        <img src={props.img} alt="" />
                  </div>
          </div>
    </>
  )
}

export default serviceCards