import React from 'react'
import logo from "../../images/Group11342.png"
import phoneIcon from "../../images/contacts/phone.png"
import emailIcon from "../../images/contacts/email.png"
import facebookIcon from "../../images/contacts/facebook.png"
import linkedinIcon from "../../images/contacts/linkedin.png"
import twitterIcon from "../../images/contacts/twitter.png"
import "./footer.css"


function Footer() {
    const phoneNumber = "+9 1XXXXXXX111";
    const socialEmail = "abcd@gmail.com";
  return (
   <>
        <div className='footerSection'>
                <div className='footerCompanySection'>
                    <div className='footer-logo'>
                        <img src={logo} alt="" className="footerlogoImg" />
                    </div>
                    <div className='contact-footer'>
                        <div style={{display:"inline-flex"}}>
                            <div className="contactimage"><img src={phoneIcon} alt="" /></div>
                            {phoneNumber}
                        </div>
                        <div style={{display:"inline-flex"}}>
                            <div className="contactimage"><img src={emailIcon} alt="" /></div>
                            {socialEmail}
                        </div>
                    </div>
                    <div className='policy-footer'>
                            <a href="/terms">Terms and Condition</a>
                            <a href="/privacyPolicy">Privacy policy</a>
                    </div>
                </div>
                    <div className="socialHandal">
                        <div className="social">
                            <span><img src={facebookIcon} alt=""  className="socialIcon" /></span>
                            <span><img src={twitterIcon} alt="" className="socialIcon" /></span>
                            <span><img src={linkedinIcon} alt=""  className="socialIcon"/></span>
                        </div>
                        <div className="copyright">
                            <p>All Right Reserved</p>
                        </div>
                    </div>
        </div>
   </>
  )
}

export default Footer