import React,{useState} from "react";
import logo from "../../images/Group11342.png"

import './Navbar.css';

const Header = () => {

const [isOpen, setIsOpen] = useState(false);
const [activeButton, setActiveButton] = useState("home");

const toggleMenu = () => {
    setIsOpen(!isOpen);
};
const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
};

    return (
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} alt="Logo" />
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>☰</button>
        <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
                <li
                    className={`${activeButton === 'home' ? 'activeShow' : ''}`}
                    onClick={() => handleButtonClick('home')}
                >
                    <a href="#home">Home</a>
                </li>
                <li
                    className={`${activeButton === 'about' ? 'activeShow' : ''}`}
                    onClick={() => handleButtonClick('about')}
                >
                    <a href="#about">About Us</a>
                </li>
                <li
                    className={`${activeButton === 'contact' ? 'activeShow' : ''}`}
                    onClick={() => handleButtonClick('contact')}
                >
                    <a href="#contact">Contact Us</a>
                </li>
                <li
                    className={`${activeButton === 'faqs' ? 'activeShow' : ''}`}
                    onClick={() => handleButtonClick('faqs')}
                >
                    <a href="#faqs">FAQ'S</a>
                </li>
              <li className="download"><a href="#download" className="download-link">Download App</a></li>
        </ul>
      </nav>
    );
  };
  
  export default Header;