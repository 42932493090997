import React from 'react'
// import "./css/form.css"

function form() {
  return (
    <>
        <div className="createContactForm">
                <div className="form-container">
                    <form action="#" method="POST">
                        <div className="form-group">
                            <label for="email">Email:</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label for="message">Message:</label>
                            <textarea rows="2" cols="40" type="password" id="message" name="message" required />
                        </div>
                        <div className="form-group submit-container">
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                </div>
        </div>
    </>
  )
}

export default form;