import React from 'react'

function Card(props) {
  return (
    <>
    <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-4 cardImg">
                    <img src={props.img} className="img-fluid rounded-start" style={{height: "100%"}} alt="..."/>
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">{props.title}</h5>
                        <p className="card-text">{props.detail}</p>
                    </div>
                </div>
            </div>
    </div>
    </>
  )
}

export default Card
