import React from 'react'
import Point from "../Advantages/pointAdvantage.js"
import iphoneimg from '../../images/iPhone14.png';
import backimg from '../../images/Group11343.png';
import starImg1 from "../../images/Shape.png"
import boxShadow from "../../images/boxShadow.png"
import "./features.css";

function Features() {
  return (
    <div className='feature-flex-container'>
        <div className='feature-container'>
                <img src={iphoneimg} alt="" className='feature-overlay' />
                <img src={backimg} alt="" className='feature-background' />
        </div>
        <div className='featureSection'>
            <div className="circleborder" id='back-4'><img src={boxShadow} alt="" /></div>
            <h1>
                App <img src={starImg1} alt="" className="" />
                <br /> 
                &nbsp;&nbsp;&nbsp;Features
            </h1>
            <div className='featuresPoints'>
                <Point detail={"dnjdhu fgiegfug ugfuguf uggfgubfjg uiugdufghu gygffgdufjbfuiug dgfui"} />
                <Point detail={"dnjdhu fgiegfug ugfuguf uggfgubfjg uiugdufghu gygffgdufjbfuiug dgfui"} />
                <Point detail={"dnjdhu fgiegfug ugfuguf uggfgubfjg uiugdufghu gygffgdufjbfuiug dgfui"} />
                <Point detail={"dnjdhu fgiegfug ugfuguf uggfgubfjg uiugdufghu gygffgdufjbfuiug dgfui"} />
            </div>
        </div>
    </div>
  )
}

export default Features;