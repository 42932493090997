import React from "react";
import { useState } from "react";
import boxShadow from "../../images/boxShadow.png"
import downArrow from "../../images/downArrow.png"
import "./Accordion.css";

export default function Accordion() {
  const [selected, setselected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setselected(null);
    }
    setselected(i);
  };


  return (
    <>
      <div className="FAQs">
          <div id="faqs">
              <h1>Frequent Asked Question</h1>
          </div>
            <div className="wrapper">
              <div className="accordion">
                {ques.map((item, i) => (
                  <div className="item">
                    <div className="question" onClick={() => toggle(i)}>
                      <p>{item.question}</p>
                      <div style={{width: "1%",fontSize: "25px",position:" relative",right: "2%"}} 
                            className={`arrow ${selected === i ? 'downArrow' : '' }`}>
                            <img src={downArrow} alt=""/>
                      </div>
                    </div>
                    <div className={selected === i ? "content aShow" : "content"}>
                      <p>{item.answer}</p>
                    </div>
                      <hr style={{color:"white"}} />
                  </div>
                ))}
              </div>
            </div>
            <div className="circleborder" id='back-5'><img src={boxShadow} alt="" /></div>
      </div>
    </>
  );
}

let ques = [
  {
    question: "What is React?",
    answer: "lorem45 4 4548d4548 88458 bhidhv hvusihdf vufdshfu gfuidsgfigyds fugdyigfdg fug udgfu sgfui gsfugdui fgdg fuiigsdfugduigfui gfu udugfui duvui dgu tsf7 gaiohs gfg uosjbch ay f dyufug dg fudgfu dfhdsyf gfdui sg481 54 894 556 487 hv hb",
  },
  {
    question: "Explain how virtual Dom is better than real Dom to render Data?",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hbLorem ipsum dolor sit amet consectetur adipisicing elit. Nam recusandae vitae doloremque dicta nihil, quia ea veritatis quis deleniti consectetur fugiat temporibus repudiandae, sit cumque quo perspiciatis ad quidem accusamus culpa. Quisquam nobis dicta ipsam.",
  },
  {
    question: "Explain the advantages of functional components over Class components?",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hbLorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ipsam, voluptatibus quasi consequatur sunt dolore laborum facere doloribus repellat. Voluptatem reiciendis amet illum doloribus odio obcaecati iure repudiandae ut. Consequatur architecto cupiditate labore ducimus. Iusto quasi vel consectetur eaque inventore rerum eligendi in. Voluptatibus et magni earum in officiis commodi?",
  },
  {
    question: "Explain the working of React Router DOM?Explain its Advantages?",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  },{
    question: "Explain how module,css is different tha CSS?",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  },{
    question: "Explain the working model of NodeJS?",
    answer: "lorem45 4 4548d4548 88458 481 54 894 556 487 hv hb",
  }
];
