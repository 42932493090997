import React ,{useState} from "react";
import img1 from "../../../images/homeBackImg1.png"
import img2 from "../../../images/homeBackImg2.png"
import logo from "../../../images/Group11342.png"
import homeBtn1 from "../../../images/homeBtn2.png"
import homeBtn2 from "../../../images/homeBtn1.png"
import "./home.css"


function Home(){
    const [activeButton, setActiveButton] = useState("button1");

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    return (
        <>
        <div className="headBody"  id="home">
            <div className="intro">
                <img src={logo} alt="" id="logo" />  
                <h2>Make Your Life Much Easier With FYC,S Ridess</h2>
                <p>ma sdjdfi hhh dsfhud fdgfuibjdb fhudfio Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate maxime odit  </p>
                    <button className={`introBtn ${activeButton === 'button1' ? 'show' : ''}`} onClick={() => handleButtonClick('button1')} >
                        Get Started
                        <img src={homeBtn1} alt="" className="homeBtn1" />
                    </button>
                    <button className={`introBtn ${activeButton === 'button2' ? 'show' : ''}`} onClick={() => handleButtonClick('button2')} >
                        <img src={homeBtn2} alt="" className="homeBtn2"/>
                        Watch Video
                    </button>
            </div>
            <div className="headContainer">
                    <img src={img1} alt="" id="head-overlay"/ >
                    <img src={img2} alt="" id="head-background" / >
            </div>
        </div>
        </>
    )
};

export default Home;