import React from 'react'
import "./Services.css"
import ServiceCards from './serviceCards'
import img from "../../images/Background.png"
import triangleBack from "../../images/triangleBack.png"
import triangleBack2 from "../../images/triangleBack2.png"

function Services() {
  return (
    <>
        <div className='serviceSection'>
        
            <div className="servicesHeader">
                <div className="triangleBack" id='tri-1'><img src={triangleBack} alt="" /></div>
                <p>OUR AWESOME SERVICES</p>
                <h1>Best Car Services For You</h1>
                <div className="triangleBack" id='tri-2'><img src={triangleBack2} alt="" /></div>
            </div>
            <div className="servicePoints">
                <ServiceCards img={img} title={"lorem sahuh sjkdj"} detail={"lorem dji fgug fg fyd f ydgf fg dyfsybhd gf dsd f dgf svhdv yds fgd dgsgd g dsfd u"} />
                <ServiceCards img={img} title={"lorem sahuh sjkdj"} detail={"lorem dji fgug fg fyd f ydgf fg dyfsybhd gf dsd f dgf svhdv yds fgd dgsgd g dsfd u"} />
                <ServiceCards img={img} title={"lorem sahuh sjkdj"} detail={"lorem dji fgug fg fyd f ydgf fg dyfsybhd gf dsd f dgf svhdv yds fgd dgsgd g dsfd u"} />
            </div>
        </div>
    </>
  )
}

export default Services


