import './App.css';
import React from 'react';
import * as ReactDOM from "react-dom/client";
import {  createBrowserRouter, RouterProvider, } from "react-router-dom";
import HomePage from './components/homePage';
import Terms from "./components/HomePage/S/terms.jsx"
import Privacy from "./components/HomePage/S/privacy.jsx"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/terms",
    element: <Terms />
  },
  {
    path: "/privacyPolicy",
    element: <Privacy />
  }
]);

function App() {
  return (
    <>
       <RouterProvider router={router} />  
    </>
  )
}

export default App
