import React from 'react'
import vector from "../../images/pointTick.png"
import "./Advantages.css"

function Point(props) {
  return (
    <>
        <div className='points'>
                <div className='pointsImg'>
                  <img src={vector} alt=''/>
                </div>
                <p>{props.detail}</p>
        </div>
    </>
  )
}

export default Point;