import React from "react";
import Point from "./pointAdvantage";
// import Factors from "./advantageFactors";
import percentage from "../../images/%.png"
import "./Advantages.css"
import shape from "../../images/shape_3.png"

function Advantages() {
  return (
    <>
      <div className="advantage-flex-Container">
        <div className="pointsAdvantages flexitem">
          <p style={{letterSpacing:"0.1rem"}}>FYC'S RIDESS</p>
          {/* <br/> */}
          <h1>
            Best Advantage
            <br /> For You
          </h1>
          <Point detail={"lorem12 asjjn ashusn jgausbjdjnnj gdsydvhv dfjn g hjbg r grhbbhjv jbj f "} />
          <Point detail={"lorem12 asjjn ashusn jgausbjdjnnj gdsydvhv dfjn g hjbg r grhbbhjv jbj f "} />
          <Point detail={"lorem12 asjjn ashusn jgausbjdjnnj gdsydvhv dfjn g hjbg r grhbbhjv jbj f "} />
        </div>
        <div className="factorsAdvantage flexitem">
              <div className="factAdvantge" style={{gap:"50px",justifyContent:"flex-start"}}>
                <img src={shape} alt="" className="factorsShape"/>
                <div id="keyItems01" className="factors">
                  <div className="borderCont">
                    <div className="perCent">
                        <h1>{keyFeatures[0].acc}</h1> 
                        <div className="perCentImg">
                          <img src={percentage} alt=""></img>
                        </div>
                    </div>
                    <br/>
                    <div className="factorHeader" style={{justifyContent:"space-around" , gap:"0"}}><h4>{keyFeatures[0].title}</h4></div>
                    <div className="factorDetail"><p>{keyFeatures[0].detail}</p></div>
                  </div>
                </div>
              
              </div>
              <div className="factAdvantge" >
                  <div id="keyItems11" className="factors">
                  <div className="borderCont">
                      <div className="perCent">
                          <h1>{keyFeatures[1].acc}</h1> 
                          <div className="perCentImg">
                            <img src={percentage} alt=""></img>
                          </div>
                      </div>
                      <br/>
                      <div className="factorHeader"><h4>{keyFeatures[1].title}</h4></div>
                      <div className="factorDetail"><p>{keyFeatures[1].detail}</p></div>
                    </div>
                  </div>
                  <div id="keyItems21" className="factors">
                  <div className="borderCont">
                    <div className="perCent">
                        <h1>{keyFeatures[2].acc}</h1> 
                        <div className="perCentImg">
                          <img src={percentage} alt=""></img>
                        </div>
                    </div>
                    <br/>
                    <div className="factorHeader"><h4>{keyFeatures[2].title}</h4></div>
                    <div className="factorDetail"><p>{keyFeatures[2].detail}</p></div>
                  </div>
                  </div>
              </div>

        </div>
      </div>
    </>
  );
}

export default Advantages;


const keyFeatures=[
  {
    acc:"96",
    title:"FAST PICKUP",
    detail:"lorem14 sndsjdh hudehj efguibv dhdgfdiygsdh sdgdhsbdgdg"
  },{
    acc:"100",
    title:"SAFE GURARANTEE",
    detail:"lorem14 sndsjdh hudehj efguibv dhdgfdiygsdh sdgdhsbdgdg"
  },{
    acc:"92",
    title:"FAST PICKUP",
    detail:"lorem14 sndsjdh hudehj efguibv yyydhdgfdiygsdh sdgdhsbdgdg"
  }
]