import React from 'react'
import Home from "./HomePage/S/home.jsx"
import About from './About/About.js';
import Advantages from './Advantages/Advantages.js';
import Features from './Features/features.js';
import Easy from './Easy/Easy.js';
import Need from './Need/Need.js';
import Testimonial from './Testimonials/Testimonial.js';
import Accordion from './FAQs/Accordion.js'
import Navbar from "./Navbar/Navbar.jsx";
import Services from './Services/Services.js';
import Contact from './Contacts/Contact.js';
import Footer from './Footer/footer.jsx';
import Download from './Download/Download.js';
import "../App.css"

function HomePage() {
  return (
    <>
        <section className='head'> 
            <Navbar />
            <Home /> 
    </section>
    <section>
          <Services />
          <About />
          <section className='backgray'>
              <Advantages />
          </section>
          <Features />
          <Need />
          <Easy />
          <Testimonial /> 
          <Accordion />
          <Contact />
    </section>
    <section className='downloadSec'>
          <Download />
    </section>
    <section className='footer'>
      <Footer />
    </section>
    </>
  )
}

export default HomePage;