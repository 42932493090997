import React from 'react'
import ratingStars from "../../images/ratingStarts.png"
function Feedback(props) {
  return (
    <>
        <div className='review'>
            <div className="reviewImg">
                <img src={props.review.img} alt=""/>
            </div>
            <div className="reviewDetail">
                <h1>
                    {props.review.rating}
                    <span style={{fontSize:"14px"}}>&nbsp;Stars</span>
                    <br />
                    <span><img src={ratingStars} alt="" /></span>
                </h1>
                <h8 style={{fontSize:"14px"}}>{props.review.desciption}</h8>
                <div className='reviewRateby'>
                    <p style={{color:"rgba(255, 255, 255, 1)"}}>{props.review.name}</p>
                    <p style={{fontSize:"10px"}}>{props.review.address}</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Feedback