import React from "react";
import iPhoneimg from "../../images/iPhone-13-Pro-Front.png";
import boxShadow from "../../images/boxShadow.png"
import "./Easy.css"

function Easy() {
  return (
    <>
        <div className="easyToRide">
            <h1 className="easyToRideHeader">
                Easy To Click To Book Your Ride with FYC's Ridess
            </h1>
            <div className="easySection">
              <div className="circleborder" id='back-2'><img src={boxShadow} alt="" /></div>
                <div className="easySectionImg">
                  <img src={iPhoneimg} alt="" id="easyIphone" />
                </div>
                <div className="easySectionDesc">
                  <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis,
                        voluptatem pariatur ducimus maiores porro eius vitae dolorem tenetur
                        voluptas architecto reiciendis iusto consectetur sequi ut?
                    </p>
                </div>
                <div className="circleborder" id='back-3'><img src={boxShadow} alt="" /></div>
            </div>
        </div>
    </>
  );
}

export default Easy;
